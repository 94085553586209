import $ from 'jquery';

class Accordion {
    constructor(accordion) {
      this.accordion = accordion;
  
      this.items = [...accordion.querySelectorAll('.js_accordion-item')].map(item => ({
        element: item,
        header: item.querySelector('.js_accordion-header'),
        body: item.querySelector('.js_accordion-body'),
        content: item.querySelector('.js_accordion-body-content')
      }));
    }
  
    init() {
      this.attachEvents();
    }
  
    attachEvents() {
      this.items.forEach(item => item.header.addEventListener('click', (event) => this.handleAccordionClick(event, item)));
    };
  
    handleAccordionClick(event, item) {
      if (item.element.classList.contains('accordion--active')) {
        item.body.style.height = 0;
      } else {
        item.body.style.height = item.content.clientHeight + "px";
      }
  
      this.items.forEach(i => {
        if (i !== item) {
          i.element.classList.remove('accordion--active');
          i.body.style.height = 0;
        }
      });
  
      item.element.classList.toggle('accordion--active');
    }
  }
  
  $(function () {
    const $accordions = [...document.querySelectorAll('.js_accordion')];
  
    $accordions.forEach(accordion => {
      new Accordion(accordion).init();
    });
  });