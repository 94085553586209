import $ from 'jquery';

class Header {
    constructor(header) {
        this.header = header;
        this.dropdownElements = header.querySelectorAll('.js_dropdown-item');
        this.subMenuContainers = header.querySelectorAll('.js_dropdown');
        //this.dropdownLinks = header.querySelectorAll('.js_dropdown-link');
        this.toggler = header.querySelector('.js_toggler');
        this.overlay = header.querySelector('.js_overlay');
        this.sidemenu = header.querySelector('.js_sidemenu');
    }

    init() {
        this.attachEvents();
    }

    attachEvents() {
        this.subMenuContainers.forEach(e => e.classList.add('loaded'));
        this.sidemenu.classList.add('loaded');
        this.overlay.classList.add('loaded');

        this.toggler.addEventListener('click', () => {
            this.showSidemenu();
        });
        this.overlay.addEventListener('click', () => {
            (this.toggler.classList.contains('active')) ? this.showSidemenu() : ''
        });

        this.dropdownElements.forEach(element => element.addEventListener('mouseover', (event) =>
                this.overlay.classList.add('active')
            ));
        this.dropdownElements.forEach(element => element.addEventListener('mouseout', (event) =>
                (this.toggler.classList.contains('active')) ? '' : this.overlay.classList.remove('active')
            ));
    }

    showSidemenu() {
        $(this.toggler).toggleClass('active');
        $(this.overlay).toggleClass('active');
        $(this.sidemenu).toggleClass('active');
    }
}

$(function () {
    const headers = document.querySelectorAll('.js_header');
    headers.forEach(header => new Header(header).init());
});